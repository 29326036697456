const userservice_url = process.env.GATSBY_API_US



export const contactRequest = (payload) => {
    return fetch(`${userservice_url}/contact_request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
}