import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useRef, useState, useEffect } from "react"
import styled, {keyframes} from 'styled-components'
import {layout, space, position, flexbox} from 'styled-system'

import Logo from '../components/logo'
import Hamburger from '../components/hamburger'
import {ColorModeSwitcher} from '../components/colormode'
import LanguageSelect from '../components/languageselect'
import {MainMenu} from '../components/menu'



const FadeInAnimation =  keyframes`
from {
   opacity: 0;
 }
 to {
   opacity: 1;
 }
`;

const Header = ({ className }) => {


  const [isMenuVisible, toggleMenu] = useState(false)
  const ref = useRef()

  const lang = true

  const style = {   visibility: 'hidden'}
  useEffect(() => {
    
    setTimeout(() => ref.current.style.visibility = 'visible', 1000)
    
  }, [ref])
  
  return (
      <header ref={ref} className={`${className} ${isMenuVisible ? "fixed": ""}`} style={style} >
          <div className="main_bar" >
            <Logo {...{
              fontSize: [ 18, 28],
              fontFamily: "body",
              fontWeight: "bold",
              alignItems: "center"}}/>
            <div className="main_right_bar">
            {lang ? <LanguageSelect width={100} /> : null}
            <ColorModeSwitcher/>
            <Hamburger checked={isMenuVisible} onToggle={toggleMenu} />
            </div>
          </div>
          {isMenuVisible ? <MainMenu showMenu={toggleMenu} /> : null}
          
      </header>
  )
} 

const StyledHeader = styled(Header)`
  position: absolute;
  width: 100%;
  transition: background 0.1s ease;
  &.fixed {
    position: fixed;
    background: ${({theme: {colors: {bg}}}) => bg};
    height: 100%;
  }
  .main_bar {
    display: flex;
    justify-content: space-between;
    .main_right_bar {
      display: flex;
    }
  }
  animation-name: ${FadeInAnimation};
  animation-delay: 0.8s;
  animation-duration: 2.5s;
  animation-timing-function: ease;
  
${space}${position}`

export default StyledHeader
