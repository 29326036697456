import React from 'react'
import {useTranslations} from '../layouts/globals'
import {navigate} from 'gatsby'
import Select from './baustyle/select'
import {SelectIcons} from './icons'

export default () => {

    const {lang} = useTranslations()
    console.log('SelectIcons', SelectIcons)
    const {ActiveIcon, SelectIcon} = SelectIcons
    return <Select 
    onChange={(e) => {

        e.target.getAttribute('value') === 'en' ? navigate('/en') : navigate('/')
    }} 
    defaultValue={lang}
    width={[60, 75]}
    {...{ActiveIcon, SelectIcon}}
    >
        <div value="de">De</div>
        <div value="en">En</div>
    </Select>
} 