import React, { useRef } from "react"
import styled from 'styled-components'
import {layout, space, typography, flexbox} from 'styled-system'
import css from '@styled-system/css'

import Link from './link'

const SVGIcon = styled.svg(css({
  'circle': {
    fill: 'text'
  },
  'path': {
    stroke: 'text'
  }
}))

const LogoIcon = ({className}) =>  {

  
  return <SVGIcon className={className} width="68" height="44" viewBox="0 0 68 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.8221 28.3119V40.1799H46.485V27.8115L38.2938 22.166L29.8221 28.3119Z"  strokeWidth="2"/>
            <circle cx="21.2655" cy="25.771" r="1.35104" />
            <circle cx="28.471" cy="19.9165" r="1.35104" />
            <circle cx="48.2863" cy="18.5659" r="1.35104"/>
            <circle cx="56.8429" cy="24.4204" r="1.35104"/>
            <circle cx="53.6904" cy="27.5727" r="1.35104"/>
            <circle cx="48.7367" cy="14.5127" r="1.35104"/>
            <circle cx="27.12" cy="15.4135" r="1.35104"/>
            <circle cx="23.9676" cy="11.8105" r="1.35104"/>
            <circle cx="19.0138" cy="22.1684" r="1.35104"/>
            <circle cx="15.411" cy="19.9165" r="1.35104"/>
            <path d="M11.358 40.1819C5.63742 40.1819 1 35.4437 1 29.5988C1 23.7539 5.63742 19.0156 11.358 19.0156"  strokeWidth="2"/>
            <path d="M10.6436 18.9973C11.244 13.7844 15.186 9.33331 22.8419 10.9095"  strokeWidth="2"/>
            <path d="M48.2863 11.8101C47.7543 5.88714 41.9767 1.04204 35.2263 1.00179C28.6686 0.893308 22.842 5.7292 22.1663 11.8101"   strokeWidth="2"/>
            <path d="M57.7436 21.9428C59.2468 14.966 53.9156 9.78347 47.8359 11.1345"  strokeWidth="2"/>
            <path d="M57.1541 21.2671C62.0395 22.8085 64.6936 28.1985 63.0822 33.3061C61.7598 37.4973 57.976 40.1959 53.9614 40.1775"  strokeWidth="2"/>
            <path d="M11.358 40.1816H27.1202"   strokeWidth="2"/>
            <path d="M48.9619 40.1802H54.3661"   strokeWidth="2"/>
        </SVGIcon>
      }
    


const StyledLogoIcon = styled(LogoIcon)(layout, space)

const Logo = ({className }) => <div className={className}>
    <Link to="/"><StyledLogoIcon width={[40, 70]} pr={[2, 3]} /><span>Bauheaven</span></Link>
  </div>



const StyledLogo = styled(Logo)`
  
  a {
    text-decoration: none;
    display: flex;
    align-items: center;

    
  }
${css({
  mt: [1, 0],
  'a': {
    color: 'text',
  },
  'span': {
    paddingTop: ['1px', '2px']
    
  }
})}
${typography}${flexbox}`



export default StyledLogo