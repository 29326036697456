import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Transition from './transition'
import Header from "./header"
import Globals from "./globals"
import "./layout.css"




const Layout = ({ children, location, pageContext: {lang} }) => {


  return (
    <><Globals lang={lang}>
        <Header zIndex='header'  p={[3,4]} />
        <Transition location={location}>
          {children}
        </Transition>
        </Globals>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
