import React from 'react';
import {useTranslations, useGlobalContext} from '../layouts/globals' 

import css from '@styled-system/css'

import styled, {css as styledcss,  keyframes} from 'styled-components'
import {layout} from 'styled-system'



const DayNight = styled.label`
display: flex;
align-items: center;
 div {
      cursor: pointer;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      position: relative;
      background: transparent;
      transform: scale(.5) rotate(0deg);
      transition: transform .3s ease .1s, box-shadow .2s ease 0s;
      background: #fff;

      &:before {
          content: '';
          width: inherit;
          height: inherit;
          border-radius: inherit;
          position: absolute;
          left: 0;
          top: 0;
          transition: background .3s ease;
      }
      &:after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: -4px 0 0 -4px;
          position: absolute;
          top: 50%;
          left: 50%;
          box-shadow: 0 -23px 0 #fff, 0 23px 0 #fff, 23px 0 0 #fff, -23px 0 0 #fff, 15px 15px 0 #fff, -15px 15px 0 #fff, 15px -15px 0 #fff, -15px -15px 0 #fff;
          transform: scale(1.5);
          transition: all .3s ease;
      }
  }
  
  ${({mode}) => {
    
    return mode === "light" && styledcss` div {
      
      transform: scale(0.6) rotate(-41deg);
      transition: transform .4s ease .1s;

      background: transparent;


      &:before {
        left: 50%;
        box-shadow: -18px 0 0 #000;
        transition: background .3s ease .1s;
      }
      &:after {
          transform: scale(0);
          transition: transform .0s ease .15s;
      }
      }`
    }
  }
}
${css({
  'div' : {
    
  }
})}
${layout}
`



export const ColorModeSwitcher = () => {
  const [{mode}, dispatch] =  useGlobalContext()

  const dispatchColorMode = () => {
    const new_color_mode = mode === "dark"
    ? "light"
    : "dark"

    window.localStorage.setItem('color_mode', new_color_mode)
    dispatch({type: new_color_mode})
  }

  return <DayNight width={[45, 70]} mode={mode}>
  <div onClick={() => dispatchColorMode()}></div>
  </DayNight>
}
