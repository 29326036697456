import React from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import {space, color} from 'styled-system'


export const H1 = styled.h1`
${({theme}) => {
    return css({...theme.styles.h1})}
}
${space}
`
export const H1Descr = styled.div`
${({theme}) => {
    return css({...theme.styles.h1Descr})}
}${space}`


export const H2 = styled.h2`
${({theme}) => {
    return css({...theme.styles.h2})}

}}
`

export const H2Descr = styled.div`
${({theme}) => {
    console.log('H2Descr', theme.styles.h2Descr)
    return css({...theme.styles.h2Descr})}
}${space}`



export const H3 = styled.h3`
${({theme}) => {
    return css({...theme.styles.h3})}
}${color}`





export const H3Descr = styled.div`
${({theme}) => {
    return css({...theme.styles.h3Descr})}
}
`
export const H4 = styled.h4`
${({theme}) => {
    return css({...theme.styles.h4})}
}`

