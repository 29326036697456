// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-mdx-about-js": () => import("./../src/layouts/mdx/about.js" /* webpackChunkName: "component---src-layouts-mdx-about-js" */),
  "component---src-layouts-mdx-datamanagement-js": () => import("./../src/layouts/mdx/datamanagement.js" /* webpackChunkName: "component---src-layouts-mdx-datamanagement-js" */),
  "component---src-layouts-mdx-legals-js": () => import("./../src/layouts/mdx/legals.js" /* webpackChunkName: "component---src-layouts-mdx-legals-js" */),
  "component---src-layouts-mdx-portfolio-js": () => import("./../src/layouts/mdx/portfolio.js" /* webpackChunkName: "component---src-layouts-mdx-portfolio-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

