import { H3Descr, H1, H1Descr, H3 } from "../../../../src/components/headers";
import { Box } from "../../../../src/components/layout";
import css from '@styled-system/css';
import { DocsIntegration, ModelIntegration, IntergrationWrapper, IntegrationFlex, BottomBox } from "../../../../src/components/integrationtargets";
import ContactForm from "../../../../src/components/contactform";
import * as React from 'react';
export default {
  H3Descr,
  H1,
  H1Descr,
  H3,
  Box,
  css,
  DocsIntegration,
  ModelIntegration,
  IntergrationWrapper,
  IntegrationFlex,
  BottomBox,
  ContactForm,
  React
};