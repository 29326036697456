import React from 'react'
import styled from 'styled-components'
import {H3, H4} from  './headers'

import css from '@styled-system/css'
import {Copywrite} from '../components/footer'
import {useTranslations} from '../layouts/globals'
import Link from './link'


const SerivicesMenu = ({className}) => {

    const {translations: {menu}} = useTranslations()

    const {services, web, web_descr, integration, integration_descr } = menu

    return <div className={className}>
    <H3 color="#b4b4ce">{services}</H3>
    <ul>
        <Link to="/web"><li>
            <H4>{web}</H4>
            <span>{web_descr}</span>
        </li></Link>
        <Link to="/integration"><li>
            <H4>{integration}</H4>            
            <span>{integration_descr}</span>
        </li></Link>
    </ul>
  </div>
} 

const StyledSerivicesMenu = styled(SerivicesMenu)`
    
margin-top: 90px;

  h3 {
    margin: 10px;
    font-weight: 300;

  }
 
  ul {
    width: 100%;
    display: flex;    
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;

    
  }
  a {
    margin: 10px;  
    max-width: 300px;
    box-shadow: 0px 0px 2px 0px #ddd;
    padding: 20px;
    padding-top: 15px;
    border-radius: 6px;
    text-decoration: none;
    background:  linear-gradient(-45deg, ${({theme}) => theme.colors.bg}, ${({theme}) => theme.colors.input});
    
    &:hover {
      box-shadow: 0px 0px 25px 1px ${({theme}) => theme.colors.border};
      background: ${({theme}) => theme.colors.input_focus};
    }
    &.active {
      background: ${({theme}) => theme.colors.input_focus};
      color: ${({theme}) => theme.colors.active};
      &:hover {
        box-shadow: none;
        
      }
    }
  }
  h4{
      font-size: 22px;
      font-weight: 500;
      transition: background 0.2s ease;
      text-decoration: none;
      margin: 0;
      padding-bottom: 5px;

      
  }
  span {
      display: block;
      font-size: 16px;
      font-weight: 200;
  }
  ${css({
      'a:hover': {
          color: 'active'
      }
  })}
`

const RequiredMenu = ({className}) => {
    const {translations: {menu}} = useTranslations()

    const {aboutus, gdpr, imprint} = menu
    
    return <div className={className}><ul>
        <li><Link to="/aboutus">{aboutus}</Link></li>
        <li><Link to="/gdpr">{gdpr}</Link></li>
        <li><Link to="/imprint">{imprint}</Link></li>
    </ul></div>
}

const SyledRequiredMenu = styled(RequiredMenu)`
  ul {
    margin: 10px;
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
  }

  li {
      margin-right: 25px;

  }

  a {
      font-size: 18px;
      &.active {
        color: ${({theme}) => theme.colors.active};
      }
  }

  
  ${css({
    'a:hover': {
        color: 'active'
    }
})}
`


const Menu = ({className, showMenu}) => {

  return <div onClick={_ => showMenu(false)} className={className}>
    <StyledSerivicesMenu />
    <SyledRequiredMenu />
    <Copywrite position="fixed" bottom="0" width="100%"/>
  </div>
}



export const MainMenu = styled(Menu)`
  

`