import React from 'react'
import styled from 'styled-components'
import {position, layout, space} from 'styled-system'

const Box = styled.div`    
    text-align: center;
    font-size: 14px;
    padding: 10px;
`
const CopywriteText = ({className}) => <div className={className}><Box>
Copyright © 2020 Bauheaven.com
</Box></div>

export const Copywrite = styled(CopywriteText)(position, layout, space)