import React, { useState, useEffect, useReducer, useRef } from 'react'

import styled, {keyframes, css as componentscss} from 'styled-components'
import css from '@styled-system/css'
import loader from '../images/svg/loader.svg'
import {H2} from './headers'
import {contactRequest} from '../utils/request'
import {Error, CheckCircle} from './icons'
import { useTranslations } from '../layouts/globals'



const Wrapper = styled.div`

    margin-top: calc(100vh - 74vh);
    max-width: 800px;
    .leftborder {
        content: " ";
        display: block;
        border-bottom: 1px solid;
        border-left: 1px solid;
        border-bottom-left-radius: 35px;
        width: 101px;
        left: -102px;
        top: -284px;
        height: 360px;
        position: absolute;
    }

    ${css({
        position: 'relative',
        bg: 'heading_label',

        mb: 5,
        p: 4,
        boxShadow: 'heading_label',
        '.leftborder': {
            borderColor: 'border',
            width: [11, 101],
            left: [-12, -102],
        }

    })}
`




const shake = keyframes`
  from, to {
    transform: translate3d(0, 0, 0);
  }

  20%, 60% {
    transform: translate3d(-3px, 0, 0);
  }

  40%, 80% {
    transform: translate3d(3px, 0, 0);
  }
`



const animatedOptions = componentscss`
        animation: ${shake} 0.3s ease;        
`

const InputWrapper = styled.div`
  
  
position: relative;
label {
  color: #999;
  position: absolute;
  line-height: 130%;
  top: 0;    
  left: 0;
  transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  pointer-events: none;
}
.invalid {
    ${animatedOptions}
    font-size: 0.75em;
    color: #f34646;
    position: absolute;
    line-height: 130%;
    top: -1.25rem;
    right: 10px;
}
input, textarea {
    width: 100%;
    border: 0;
    ~ .border {
        // Border Effect
        display: block;
        width: 0;
        height: 2px;
        background: ${({theme}) => theme.colors.active};
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
    }
    &:focus {
        // Trigger the effects
        outline: 0;
        ~ .border {
            // Expand across
            width: 100%;
            transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
        }
        ~ label {
            font-size: 0.75em;
            color:  ${({theme}) => theme.colors.active};
            top: -1.75rem;
            transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);        
        }
    }
    &.non-empty ~ label {
        font-size: 0.75em;
        color:  ${({theme}) => theme.colors.active};
        top: -1.75rem;       
    }
}
${css({
    'label': {
        p: 2,
        pl: 3
    },
    'input, textarea': {
        fontFamily: 'body',
        color: 'text',
        fontSize: 3,
        p: 2,
        pl: 3,
        bg: 'input',
        '&:focus': {
            bg: 'input_focus'
        }
    }
})}
`


const Input = ({label, rule, onInvalid, onChange, onBlur, onFocus, textarea,  ...rest}) => {

    const [value, setValue] = useState()

    const [is_not_valid, setNotValid] = useState(false)

    const props = {
        className: value && value.length > 0 ? `non-empty`: ``,
        onChange: (e) => setValue(e.target.value) || (onChange && onChange(e)),
        onFocus: (e) => setNotValid(false) || (onFocus && onFocus(e)),
        onBlur:  (e) => {
            const result = e.target.value.match(rule)
            if(!result) {
                setNotValid(true)
            }else {
                setNotValid(false)
            }
            onBlur && onBlur(e)
        },
        ...rest}


    const Invalid = onInvalid
    return <InputWrapper>
        {textarea ? <textarea {...props} /> : <input {...props}/>}        
        <label>{label}</label>
        {is_not_valid ? <span className='invalid'><Invalid /></span> : null}
        <span className='border'/>
    </InputWrapper>
}


const Textarea = (props) => <Input {...{textarea: true, ...props}} />


const ButtonWrapper = styled.div`
  button {
    background: transparent;    
	color: inherit;
	text-decoration: none;
	border: 0;
	transition: all 200ms ease-in;
	display: inline-block;
    cursor: pointer;

	
    color: #4683f3;
    font-weight: bold;
    text-transform: uppercase;
    border: 4px solid #4683f3;
    padding: 12px 28px;
    
    &:hover:enabled {
        background: #4683f3;
        color: white;
    }
    
	transform: skew(-10deg);
		
    > span {
        transform: skew(10deg);
        display: inline-block;
    }
}
${css({
    'button:disabled': {
        bg: 'input',
        cursor: 'default',
        color: '#999',
        borderColor: 'border',
        
    }
})}
`

const Submit = ({children, disabled, ...rest}) => {
    
    const ref = useRef();

    return <ButtonWrapper>
        <button ref={ref} disabled={disabled} {...rest}><span>{children}</span></button>
    </ButtonWrapper>

}
    
const Box = styled.div(css({
    mt: 4
}))

const Flex = styled.div`
    display: flex;
    ${css({
        flexDirection: ['column', 'column', 'row'],
        '&>div': {
            width: ['100%', '100%','50%'],
            mt: [4, 4,0]
        },
        '&>div:last': {
            mt: [4, 4,0]
        }
    })}
`
const Separator = styled.span`
transform: skew(-10deg);
${css({
    position: 'absolute',
    left: 'calc(50% - 17px)',
    width: [0, 0, '20px'],
    height: '2.5em',
    bg: 'heading_label'
})}
`

const LoadingWrapper = styled.div`
top: -74px;
left: 174px;
position: relative;
img {
    width: 60px;
    
}
`


const contactFormReducer = (state, action) => {
    switch (action.type) {
        case 'complete':
            return {...state, is_complete: true}
        case 'not_complete':
            return {...state, is_complete: false}
        case 'submit':
            return {...state, is_submited: true, payload: action.payload}
        case 'request_succeded':
            return {...state, is_submit_succeeded: true, payload: null, is_submited: false}
        case 'request_failed':
            return {...state, is_submit_succeeded: false, is_submited: false}
        default : 
            return state            
    }

    return state

}

export default ({leftborder}) => {

    const initState = {is_complete: false, is_submited: false, is_submit_succeeded: null, payload: null}
    const [{is_complete, is_submited, payload, is_submit_succeeded}, dispatch] = 
        useReducer(contactFormReducer, initState)

    
    useEffect(() => {
        if(!is_submited) return

        contactRequest(payload)
            .then(_ => dispatch({type: 'request_succeded'}))
            .catch(_ => dispatch({type: 'request_failed'}))

    }, [is_submited])
    

    return <Wrapper>
        {leftborder? <span className='leftborder'/> : null}
        <ContactForm {...{is_complete, dispatch, is_submited, is_submit_succeeded}}/>
        {is_submited 
            ? <LoadingWrapper><img src={loader} /></LoadingWrapper>
            : <SubmitResult {...{is_submit_succeeded}}/>
        }
    </Wrapper>
}



const ContactForm = ({is_complete, dispatch, is_submit_succeeded}) => {

    const {translations: {contact_form: {header,
        name_label,
        check_name,
        email_label,
        check_email,
        message_label,
        check_message,
        submit}}} = useTranslations()
            

    const [payload, setPayload] = useState({name: "", email: "", message: ""})

    const rules = {
        name: /\w+/,
        email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        message: /\w+/,
    }


    const isValid = (name, value) => (new RegExp(rules[name])).test(value)

    const updatePayload = (e) => {
        const {name, value} = e.target
        setPayload({...payload, [name]: value})

        if(!isValid(name, value)){
            dispatch({'type': 'not_complete'})  
        }
    }

    useEffect(() => {
        Object.keys(payload).reduce((acc,key) => acc && isValid(key, payload[key]), true)
        ? dispatch({'type': 'complete'})
        : dispatch({'type': 'not_complete'})
        
    }, [payload])



    const form = <div>
    <H2>{header}</H2>
    <Box>
        <Flex>
            <Input 
                label={name_label} 
                name="name" 
                type="name" 
                rule={rules.name}
                onInvalid={() => check_name} 
                onBlur={updatePayload}
             />
            <Input 
                label={email_label} 
                name="email" 
                type="email"
                rule={rules.email}
                onInvalid={() => check_email}
                onBlur={updatePayload}

                />
            <Separator/>

        </Flex>
    </Box>
    <Box>
        <Textarea
        name="message" 
        rows={5} 
        label={message_label}
        rule={rules.message}
        onInvalid={() => check_message} 
        onChange={updatePayload}

        />
    </Box>
    <Box>
        <Submit disabled={!is_complete} onClick={() => dispatch({type: 'submit', payload})}>{submit}</Submit>
    </Box>
    </div>

    return is_submit_succeeded !==null 
    ? <FadeOutUp>{form}</FadeOutUp>
    : form
}


const slideIn = keyframes`
 
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }

`



const BaseAnimation = styled.div`
  animation-duration: ${props => props.duration};
  animation-timing-function: ${props => props.timingFunction};
  animation-delay: ${props => props.delay};
  animation-iteration-count: ${props => props.iterationCount};
  animation-direction: ${props => props.direction};
  animation-fill-mode: ${props => props.fillMode};
  animation-play-state:  ${props => props.playState};
  display: ${props => props.display};
`;


BaseAnimation.defaultProps = {
    duration: '1s',
    timingFunction: 'ease',
    delay: '0s',
    iterationCount: '1',
    direction: 'normal',
    fillMode: 'both',
    playState: 'running',
    display: 'block'
  }
const slideOut = keyframes`
 
    0% {
        top: 0px;
        opacity: 1;
    }

    50% {
        opacity: .8;
    }

    100% {
        top: -200%;
        opacity: 0;
    }

`

const FadeOutUpAnimation = keyframes`
  from {
     opacity: 1;
   }
   to {
     opacity: 0;
     transform: translate3d(0, -100%, 0);
   }
`;

const FadeOutUp = styled(BaseAnimation)`
  animation-name: ${FadeOutUpAnimation};
`;


const FadeInUpAnimation =  keyframes`
from {
   opacity: 0;
   top: 100px;
 }
 to {
   opacity: 1;
   top: -200px;
 }
`;

const FadeIn = styled(BaseAnimation)`
  animation-name: ${FadeInUpAnimation};
  position: relative;

`;




const SubmitResult = ({is_submit_succeeded}) => {
    const {translations: {contact_form: {
        submit_success,
        submit_error}}} = useTranslations()
    if(is_submit_succeeded === true) return <FadeIn><SubmitResultSuccess />{submit_success}</FadeIn>
    else if (is_submit_succeeded === false)  return <FadeIn><SubmitResultError /> {submit_error}</FadeIn>
    else return null
}
    


const slideInAnimation = componentscss`
    animation: ${slideIn}  2s ease;
`



const SubmitResultSuccess = styled(CheckCircle)`
    ${slideInAnimation}
    color: #4683f3;
    width: 50px;
`




const SubmitResultError = styled(Error)`
${slideInAnimation}
    color: #c21111;
    width: 50px;
`