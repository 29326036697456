import { StepWrapper, Label, Step, PortfolioTargets } from "../../../../src/components/landingpage";
import { H3Descr, H1, H1Descr, H2Descr, H3 } from "../../../../src/components/headers";
import ContactForm from "../../../../src/components/contactform";
import * as React from 'react';
export default {
  StepWrapper,
  Label,
  Step,
  PortfolioTargets,
  H3Descr,
  H1,
  H1Descr,
  H2Descr,
  H3,
  ContactForm,
  React
};