import React from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import {layout} from 'styled-system'
import dropbox from '../images/png/dropbox.png'
import onedrive from '../images/png/onedrive.png'
import googledrive from '../images/png/googledrive.png'
import bim360 from '../images/png/bim360.png'
import solibri from '../images/png/solibri.png'
import bimcollab from '../images/png/bimcollab.png'




const DropboxImg = ({className}) => <div className={className}><img src={dropbox}/></div>
const DropboxPipe = styled(DropboxImg)`
    position: relative;
    left: -192px;
    top: -100px;
    
    &:after {
        position: absolute;
        content: " ";
        border-top: 1px solid;
        border-right: 1px solid;
        border-top-right-radius: 35px;
        width: 192px;
        height: 100vh;
        top: 26px;
    }
    ${css({
        '&:after': {
            borderColor: 'border',
        }
    })}
    
`

const OnedriveImg = ({className}) => <div className={className}><img src={onedrive}/></div>
const OnedrivePipe = styled(OnedriveImg)`
    position: relative;
    left: -150px;
    top: -135px;

    &:after {
        position: absolute;
        content: " ";
        border-top: 1px solid;
        border-right: 1px solid;
        border-top-right-radius: 35px;
        width: 100px;
        height: 100vh;
        top: 26px;
    }
    ${css({
        '&:after': {
            borderColor: 'border',
        }
    })}
    
`
const GoogledriveImg = ({className}) => <div className={className}><img src={googledrive}/></div>
const GoogledrivePipe = styled(GoogledriveImg)`
    position: relative;
    left: -235px;
    top: -65px;
    &:after {
        position: absolute;
        content: " ";
        border-top: 1px solid;
        border-right: 1px solid;
        border-top-right-radius: 35px;
        width: 84px;
        height: 100vh;
        top: 37px;
    }
    ${css({
        '&:after': {
            borderColor: 'border',
        }
    })}
    
    `

const DIWrapper = styled.div`
display: flex;
`

export const DocsIntegration = () => <DIWrapper>
    <DropboxPipe />
    <OnedrivePipe />
    <GoogledrivePipe />
</DIWrapper>


const BimcollabImg = ({className}) => <div className={className}><img src={bimcollab}/></div>
const BimcollabPipe = styled(BimcollabImg)`
        position: relative;
        left: 234px;
        top: -200px;
        width: 0px;
        &:after {
            
            position: absolute;
            content: " ";
            border-top: 1px solid;
            border-left: 1px solid;
            border-top-left-radius: 35px;
            width: 136px;
            height: 100vh;
            top: 26px;
            left: -137px;
            
        }${css({
            '&:after': {
                borderColor: 'border',
            },
            '@media screen and (max-width: 900px)': {
                left: '-157px',
                top: '300px',
                '&:after': {            
                    borderLeft: 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 35,
                    borderRight: '1px solid',
                    left: 54,
                    borderColor: 'border',
                    
                }
              }
        })}`

const SolibriImg = ({className}) => <div className={className}><img src={solibri}/></div>
const SolibriPipe = styled(SolibriImg)`
    position: relative;
    left: 320px;
    top: -122px;
    width: 0px;
    &:after {
        position: absolute;
        content: " ";
        border-top: 1px solid;
        border-left: 1px solid;
        border-top-left-radius: 35px;
        width: 182px;
        height: 100vh;
        top: 26px;
        left: -182px;
    }
    @media screen and (max-width: 900px) {
        left: -247px;
        top: 340px;
        &:after {            
            border-left: none;
            border-top-left-radius: 0;
            border-top-right-radius: 35px;
            border-Right: 1px solid;
            left: 51px; 
            width: 251px;                   
        }
      }

      ${css({
        '&:after': {
            borderColor: 'border',
        }
    })}
    
    `        

const Bim360Img = ({className}) => <div className={className}><img src={bim360}/></div>
const Bim360Pipe = styled(Bim360Img)`
    position: relative;
    left: 161px;
    top: -160px;
    width: 0px;
    &:after {
        position: absolute;
        content: " ";
        border-top: 1px solid;
        border-left: 1px solid;
        border-top-left-radius: 35px;
        width: 50px;
        height: 100vh;
        top: 26px;
        left: -44px;
    }
    @media screen and (max-width: 900px) {
        left: -87px;
        top: 380px;
        &:after {            
            border-left: none;
            border-top-left-radius: 0;
            border-top-right-radius: 35px;
            border-Right: 1px solid;
            left: 38px; 
            width: 125px;                   
        }
      }
      ${css({
        '&:after': {
            borderColor: 'border',
        }
    })}
    
    `        

export const ModelIntegration = () => <DIWrapper>
    <BimcollabPipe />
    <SolibriPipe />
    <Bim360Pipe />
</DIWrapper>



export const IntergrationWrapper = styled.div`
 ${css({
     '@media screen and (max-width: 900px)': {
         position: 'absolute',
        left: '80%',
        width:'0px',
     }

    })};
`
export const IntegrationFlex = styled.div`
    margin-top: 155px;
    position: relative;
    display: flex;
    ${css({
     
        '@media screen and (max-width: 900px)': {
           flexDirection: 'column'

        }
   
       })};
   
`

export const BottomBox = styled.div`
${css({
     
    '@media screen and (max-width: 900px)': {
       mt: '250px'
    }

   })};

${layout}
`