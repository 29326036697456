require('typeface-inter');
require('typeface-ibm-plex-sans');



const fontSizes = [12, 14, 16, 18, 24, 26]

fontSizes.body = fontSizes[2]
fontSizes.display = fontSizes[5]

export default {
    breakpoints: ['600px', '1200px'],

    colors: {
        text: '#54545c',
        link: '#272730',
        bg: '#fff',
        bgright: '#ececf0', 
        attention: '#F36F46',
        progress: '#fff',
        heading_label: '#fff',
        input_bg: '#f7f7fc',
        border: '#dfdff1',
        input: '#f7f7fc',
        input_focus: '#fff',
        active: '#4683f3',
        invert_active: '#fff',
        modes: {
            dark: {
                text: '#f1f1f1',
                link: '#f1f1f1',
                bg: '#272730',
                bgright: '#333',
                heading_label: '#4c4c4c',
                border: '#4c4c4c',
                input_bg: '#3a3939',
                input: '#3a3939',
                input_focus: '#3a3939',
                active: '#d9e7ff',


            }
        }

    },
    fontSizes,

    fontWeights: {
        bold: 700,
        header: 600,
        body: 400,
    },
    borders: {
      
    },
    borderWidths: {
        heading_label: `1px`
    },
    radii: {
        heading_label: 100
    },
    fonts: {
        body: `"IBM Plex Sans", Roboto`,
        heading: `"Inter", sans-serif`,
    },
    sizes: {
        logo: [1, 1/2]
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512], 
    shadows: {
        heading_label: '0px 4px 4px #dfdff1',
        modes: {
            dark: {
                heading_label: 'none',
            }
        }
    },
    zIndices: {
        header: 25099620
    },
    styles: {
        h1: {
            fontFamily: 'heading',
            fontSize: [32, 64],
            fontWeight: 700,
            letterSpacing: [0, '-2px'],
        },
        h1Descr: {
            fontFamily: 'heading',
            fontSize: [22, 26],
//            fontStyle: 'italic',
            fontWeight: 400,
            lineHeight: '1.2em',
            padding: 4,
            opacity: '0.8',

        },
        h2: {
            fontFamily: 'body',
            fontSize: [22, 28],
            fontWeight: 300,
            letterSpacing: [0, '-1px'],
        },        
        h2Descr: {
            fontFamily: 'heading',
            fontSize: [22, 26],
            fontWeight: 400,
            lineHeight: '1.2em',
            padding: 4,
            opacity: '0.8',
            textDecorationLine: 'underline',
        },

        h3: {
            fontFamily: 'body',
            fontSize: 18,
            fontWeight: 600,
            color: 'attention',
        },
        h3Descr: {
            fontFamily: 'body',
            fontSize: 22,
            fontWeight: 300,
        },
        h4: {
            fontFamily: 'body',
            fontSize: 18,
            fontWeight: 600,
            margin: '10px 0px',
            
        },
    }
}