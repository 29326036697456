import React from 'react'
import styled from 'styled-components'
import {flexbox, color, layout, space} from 'styled-system'
import css from '@styled-system/css'


export const Flex = styled.div`
    display: flex;
${flexbox}
${color}
`

export const Box = styled.div`
${layout}
${space}
`

export const Page = styled.div`
  margin: 0px auto;
  .mdx {
    min-height: calc(100vh - 197px)
    
  }
${css({
  p: [3,0] 
})}
${layout}`
