import React, {createContext, useContext} from 'react'
import {ThemeProvider, createGlobalStyle} from 'styled-components'
import baseTheme from './theme'
import  {graphql, useStaticQuery} from 'gatsby'


const LangContext = createContext()
const GlobalContext = createContext()


export const useGlobalContext = () => 
    useContext(GlobalContext)
    




export const useTranslations = () => {
    const { lang } = useContext(LangContext)
    // Query the JSON files in <rootDir>/i18n/translations
    const {allFile} = useStaticQuery(queryTranslations)

    // Simplify the response from GraphQL
    const simplified = allFile.edges.map(item => {
      return {
        name: item.node.name,
        translations: item.node.translations,
      }
    })
  
    // Only return translations for the current locale
    const { translations } = simplified.filter(item => item.name === lang)[0]
    return {lang, translations}
}



const queryTranslations = graphql`{allFile(filter: {sourceInstanceName: {eq: "translations"}}) {
    edges {
      node {
        name
        translations: childTranslationsJson {
          title,
          description,
          page_not_found,
          portfolio_targets {
            p1
            p2
            c1
            c2
            pa1
            pa2
            e1
            e2
          }
          hero {
          	hero_title
            portfolio_title
            portfolio_desctiption
            datamanagement_title
            datamanagement_desctiption          
          }
          menu {
            services
            web
            web_descr
            integration
            integration_descr
            aboutus
            gdpr
            imprint
          }
          contact_form {
            header
            name_label
            check_name
            email_label
            check_email
            message_label
            check_message
            submit
            submit_success
            submit_error
          }
        }
      }
    }
  }
}`


const globalReducer = (state, action) => {
  switch(action.type){
    case 'light':
    case 'dark':
      return {...state, mode: action.type}
  }
}

const getTheme = (mode) => {
  const colorsModes = baseTheme.colors.modes
  const colorsMode = colorsModes[mode]
  const shadowsMode = baseTheme.shadows.modes[mode]
  return {...baseTheme, colors: {...baseTheme.colors, ...colorsMode }, shadows: {...baseTheme.shadows, ...shadowsMode} }
}

const getDefaultColorMode = ()  => {
  const color_mode = typeof window !== 'undefined' && window.localStorage.getItem('color_mode')
  return color_mode ? color_mode : 'light'
}


const GlobalStyle = createGlobalStyle`
  body {
    background: ${({theme: {colors: {bg, bgright}}}) => `linear-gradient(90deg,${bg},${bgright});`};
    color: ${({theme: {colors: {text}}}) => text};
    font-family: ${({theme: {fonts: {body}}}) => body};
    transition: background .4s ease;
  }
  a, a:visited {
    color: ${({theme: {colors: {link}}}) => link};
  }
`


export default ({children, lang}) => {
    const [state, dispatch] = React.useReducer(globalReducer, {mode: getDefaultColorMode()})
    const theme = getTheme(state.mode)

    console.log('global mode', state.mode)
    return <LangContext.Provider value={{lang}}>
          <GlobalContext.Provider value={[{...state}, dispatch]}>
            <ThemeProvider theme={theme}>    
              <GlobalStyle />
                {children}
            </ThemeProvider>
        </GlobalContext.Provider>
    </LangContext.Provider>    
}
