import React, { useRef, useEffect } from "react"
import styled from 'styled-components'


const Hamburger = ({className, checked, onToggle}) => {

  const chechboxRef = useRef()

  useEffect(() => {
    chechboxRef.current && (chechboxRef.current.checked = checked)
  }, [chechboxRef, checked])
  
  return <div className={`${className} cross menu--2`}>
  <label >
  <input type="checkbox" ref={chechboxRef}/>
  <svg onClick={() =>{
    onToggle(!chechboxRef.current.checked) 
  } } viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <path className="line--1" d="M0 70l28-28c2-2 2-2 7-2h64" />
    <path className="line--2" d="M0 50h99" />
    <path className="line--3" d="M0 30l28 28c2 2 2 2 7 2h64" />
  </svg>
</label>
</div>

}
const StyledHamburger = styled(Hamburger)`
position: relative;
overflow: hidden;
width: 50px;
height: 50px;
input {
  display: none;
}

.grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  position: absolute;

}

.grid>* {
  position: relative;
}

label {
  display: block;
  cursor: pointer;
  position: absolute;
  width: 50vw;
  height: 50vh;
  max-width: 100px;
  max-height: 100px;
  top: -25px;
  left: -25px;
}

@media (min-aspect-ratio: 1/1) {
  label {
    width: 50vh;
    height: 50vh;
  }
}


path {
  fill: none;
  stroke: ${({theme: {colors: {text}}}) => text};
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  transition: all .2s cubic-bezier(.0, .045, .355, 1);
}

circle {
  fill: #fff3;
  opacity: 0;
}

label:hover circle {
  opacity: 1;
}

&.cross input:checked+svg {
  .line--1,
  .line--3 {
    --length: 22.627416998;
  }
  .line--2 {
    --length: 0;
  }
}

.back input:checked+svg {
  .line--1,
  .line--3 {
    --length: 8.602325267;
  }
}



&.menu--2 {
  .line--1,
  .line--3 {
    --total-length: 111.22813415527344;
    --offset: -50.22813415527344;
  }
  .line--2 {
    --total-length: 99;
  }
  input:checked+svg {
    path {
      transform: translateX(30px);
    }
    .line--1,
    .line--3 {
      --offset: -16.9705627485;
    }
    .line--2 {
      --offset: -20;
    }
  }
}

`

export default StyledHamburger