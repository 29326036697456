import React from 'react'
import {ChevronDown, Error, CheckCircle} from "@styled-icons/boxicons-solid"
import {Check} from "@styled-icons/boxicons-regular"
import styled from 'styled-components'




const IconStyle = styled.svg`
    path, circle, ellipse, line {
        stroke: ${({theme: {colors: {text}}}) => text};
    }
`
const FillIconStyle = styled.svg`
    path {
        fill: ${({theme: {colors: {text}}}) => text};
    }
`

export const CustomersIcon = () => <IconStyle  height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.1646 68.9058C34.5956 51.1784 56.8977 50.6069 60.9007 68.9058" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="46.0328" cy="46.0323" r="8.57773" stroke-width="4"/>
    <path d="M13.25 55.1819C16.3068 39.3884 36.1759 38.8792 39.7422 55.1819" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="26.496" cy="34.8031" r="7.64197"  stroke-width="4"/>
    <path d="M52.3228 55.1819C55.3795 39.3884 75.2487 38.8792 78.8149 55.1819"  stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="65.5687" cy="34.8031" r="7.64197"stroke-width="4"/>
    <path d="M80.3432 18.4326C72.2722 8.41173 59.901 2 46.0323 2C21.7139 2 2 21.7139 2 46.0323C2 52.1181 3.23463 57.9155 5.46709 63.1878M80.3432 18.4326L79.1995 7.71848M80.3432 18.4326L69.4781 16.8681" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.3811 72.0882C20.3194 82.2146 32.605 88.7887 46.4725 88.9715C70.7887 89.292 90.7608 69.8395 91.0812 45.5233C91.1614 39.438 90.0033 33.6248 87.8405 28.3236M12.3811 72.0882L13.3835 82.8164M12.3811 72.0882L23.2246 73.7958" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>    
</IconStyle>

export const PorfolioIcon = () => <IconStyle  height="112" viewBox="0 0 134 112" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M69 67L13.5 38.5L69 10L124 38.5L69 67Z" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 58L68.5 86.5L123.5 58" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 77L69.5 105.5L124.5 77"stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M52.1229 41.1313L71.1149 50.4388L92.6133 39.7862L71.7062 29.8286L56.1239 30.7859L52.1229 41.1313Z"  stroke-width="4"/>

</IconStyle>

export const PartnersIcon = () => <FillIconStyle height="97" viewBox="0 0 105 97" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.4746 33.3353C41.5973 34.8551 42.5556 36.5034 43.3238 38.2532L54.4835 31.8103C53.5937 30.1277 52.8859 28.3351 52.387 26.458L40.4746 33.3353Z" />
<path d="M40.4746 63.6644L52.3869 70.5419C52.8859 68.6648 53.5935 66.872 54.4834 65.1897L43.3236 58.7466C42.5556 60.4966 41.5973 62.1446 40.4746 63.6644Z"  />
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.0109 62.2722C37.7072 58.6431 40 53.5893 40 48C40 36.9543 31.0457 28 20 28C8.9543 28 0 36.9543 0 48C0 53.7344 2.41337 58.9052 6.27984 62.5519C8.0053 58.5349 10.9158 55.7215 14.2948 54.2288C12.0475 52.4635 10.604 49.7213 10.604 46.642C10.604 41.3169 14.9209 37 20.246 37C25.5711 37 29.8879 41.3169 29.8879 46.642C29.8879 49.7398 28.427 52.4965 26.1565 54.2605C29.3977 55.7161 32.222 58.4054 34.0109 62.2722ZM30.7665 64.8577C28.6523 59.3881 24.2484 56.9387 20.0769 56.9845C15.8424 57.031 11.4845 59.647 9.58565 65.0779C12.619 66.9317 16.1848 68 20 68C23.9639 68 27.6585 66.8468 30.7665 64.8577ZM20.246 41C17.13 41 14.604 43.526 14.604 46.642C14.604 49.7579 17.13 52.2839 20.246 52.2839C23.3619 52.2839 25.8879 49.7579 25.8879 46.642C25.8879 43.526 23.3619 41 20.246 41Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.0109 35.2722C94.7072 31.6431 97 26.5893 97 21C97 9.9543 88.0457 1 77 1C65.9543 1 57 9.9543 57 21C57 26.7344 59.4134 31.9052 63.2798 35.5519C65.0053 31.5349 67.9158 28.7215 71.2948 27.2288C69.0475 25.4635 67.604 22.7213 67.604 19.642C67.604 14.3169 71.9209 10 77.246 10C82.5711 10 86.8879 14.3169 86.8879 19.642C86.8879 22.7398 85.427 25.4965 83.1565 27.2605C86.3977 28.7161 89.222 31.4054 91.0109 35.2722ZM87.7665 37.8577C85.6523 32.3881 81.2484 29.9387 77.0769 29.9845C72.8424 30.031 68.4845 32.647 66.5856 38.0779C69.619 39.9317 73.1848 41 77 41C80.9639 41 84.6585 39.8468 87.7665 37.8577ZM77.246 14C74.13 14 71.604 16.526 71.604 19.642C71.604 22.7579 74.13 25.2839 77.246 25.2839C80.3619 25.2839 82.8879 22.7579 82.8879 19.642C82.8879 16.526 80.3619 14 77.246 14Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.0109 90.2722C94.7072 86.6431 97 81.5893 97 76C97 64.9543 88.0457 56 77 56C65.9543 56 57 64.9543 57 76C57 81.7344 59.4134 86.9052 63.2798 90.5519C65.0053 86.5349 67.9158 83.7215 71.2948 82.2288C69.0475 80.4635 67.604 77.7213 67.604 74.642C67.604 69.3169 71.9209 65 77.246 65C82.5711 65 86.8879 69.3169 86.8879 74.642C86.8879 77.7398 85.427 80.4965 83.1565 82.2605C86.3977 83.7161 89.222 86.4054 91.0109 90.2722ZM87.7665 92.8577C85.6523 87.3881 81.2484 84.9387 77.0769 84.9845C72.8424 85.031 68.4845 87.647 66.5856 93.0779C69.619 94.9317 73.1848 96 77 96C80.9639 96 84.6585 94.8468 87.7665 92.8577ZM77.246 69C74.13 69 71.604 71.526 71.604 74.642C71.604 77.7579 74.13 80.2839 77.246 80.2839C80.3619 80.2839 82.8879 77.7579 82.8879 74.642C82.8879 71.526 80.3619 69 77.246 69Z" />

</FillIconStyle>

export const EmployeeIcon = () => <IconStyle height="100" viewBox="0 0 96 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.9415 13.1846H25.7085C21.2902 13.1846 17.7085 16.7663 17.7085 21.1846V88.136C17.7085 92.5543 21.2902 96.136 25.7085 96.136H70.291C74.7093 96.136 78.291 92.5543 78.291 88.136V21.1846C78.291 16.7663 74.7093 13.1846 70.291 13.1846H54.058"  stroke-width="4"/>
<path d="M41.9417 19.7769H38.2135C36.1545 19.7769 34.4854 21.446 34.4854 23.505V23.505C34.4854 25.564 36.1545 27.2332 38.2135 27.2332H57.7863C59.8453 27.2332 61.5145 25.564 61.5145 23.505V23.505C61.5145 21.446 59.8453 19.7769 57.7863 19.7769H54.0582"  stroke-width="3"/>
<path d="M54.0584 26.2331V6.93213C54.0584 4.72299 52.2675 2.93213 50.0584 2.93213H45.9419C43.7328 2.93213 41.9419 4.72299 41.9419 6.93213V26.2331"  stroke-width="4"/>
<path d="M34.4854 63.0032C37.6833 46.4806 58.4697 45.9479 62.2006 63.0032"  stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<ellipse cx="48.3434" cy="41.6842" rx="7.99478" ry="7.99478"  stroke-width="4"/>
<line x1="30.3931" y1="73.1992" x2="65.6067" y2="73.1992"  stroke-width="3" stroke-linecap="round"/>
<line x1="30.3931" y1="79.7231" x2="65.6067" y2="79.7231"  stroke-width="3" stroke-linecap="round"/>
<line x1="37.8496" y1="86.2476" x2="58.1506" y2="86.2476"  stroke-width="3" stroke-linecap="round"/>
</IconStyle>


export const SelectIcons = {"SelectIcon": ChevronDown, "ActiveIcon": Check}
export {Error, CheckCircle} 




