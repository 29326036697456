import React from 'react'
import { color, border, layout} from 'styled-system' 
import css from '@styled-system/css'
import styled from 'styled-components'

import {PorfolioIcon, CustomersIcon, EmployeeIcon, PartnersIcon} from './icons'
import { useTranslations } from '../layouts/globals'





const LabelSpan = styled.span`
width: 4rem;
height: 4rem;
display: inline-flex;
align-items: center;
justify-content: center;

${css({boxShadow: "heading_label"})}
${color}${border}
`

const LabelDiv = styled.div`
    margin-right: 20px;
    position: relative;
    &:after {
        content: " ";
        display: block;
        border-top: 1px solid;
        border-left: 1px solid;
        border-top-left-radius: 35px;
        width: 100px;
        top: 33px;
        height: 150%;
        position: absolute;
    }
    ${css({
        '&:after': {
            width: [11, 100],
            left: [-12, -102],
            borderColor: 'border',
        }
    })}
`

export const Label = ({children}) => <LabelDiv><LabelSpan {...{
    borderRadius: "heading_label",
    borderColor: "heading_label",
    borderWidth: "heading_label",
    borderStyle: "solid",
    bg: "bg",
   
}}>{children}</LabelSpan></LabelDiv>


export const StepWrapper = styled.div`
    display: inline-flex;
    padding-bottom: 40px;
`

export const Step = styled.div`
position: relative;
${layout}
`

const StepSliderDiv = styled.div`
    color: #d7d7d7;
    font-style: italic;
`



export const StepSlider = () => <StepSliderDiv>noch keine Präfernezen</StepSliderDiv>




const TargetBox = styled.div`
padding-top: 15px;
`

const PortfolioTargetsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding-bottom: 68px;
    img, svg {
        height: 40px;
        margin-left: 8px;
    }
    p {
        width: 150px;
        margin-top: 5px;
    }
`

export const PortfolioTargets = () => {

 const {translations: {portfolio_targets: {p1, p2, c1, c2, pa1, pa2, e1, e2}}} = useTranslations()

 return <PortfolioTargetsWrapper>
    <TargetBox><PorfolioIcon/> <p><b>{p1}</b> <br/> {p2}</p></TargetBox>
    <TargetBox><CustomersIcon/> <p><b>{c1}</b>  <br/> {c2}</p></TargetBox>
    <TargetBox><PartnersIcon/> <p><b>{pa1}</b>  <br/> {pa2}</p></TargetBox>
    <TargetBox><EmployeeIcon/><p><b>{e1}</b>  <br/> {e2}</p></TargetBox>
</PortfolioTargetsWrapper>
} 