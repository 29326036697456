import React from 'react'
import {Link} from 'gatsby'
import {useTranslations} from '../layouts/globals'
import { useLocation } from '@reach/router'

const LangLink = ({to, children}) => {
    const {lang} = useTranslations()
    const { pathname } = useLocation();
    

    const path = lang === "de" ? to : `/en${to}`
    const className = pathname.includes(path) ? "active": ""
    console.log('pathname', pathname,pathname.includes(path), path)

    return <Link className={className}  to={`${path}`}>{children}</Link>
}

export default LangLink